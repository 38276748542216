import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import './style.scss'
const mwrImages = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "mwr" } }) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData (
                  layout: CONSTRAINED
                  quality: 100
                )
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Row>
        {data.allFile.edges.map(pic => (
          <Col
            className="mwr"
            sm={3}
            xs={3}
            md={3}
            key={pic.node.childImageSharp.gatsbyImageData.src }
          >
            <div>
              <GatsbyImage image={pic.node.childImageSharp.gatsbyImageData} />
            </div>
          </Col>
        ))}
      </Row>
    )}
  />
)
export default mwrImages